.thasadith-regular {
  font-family: "Thasadith", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.thasadith-regular-italic {
  font-family: "Thasadith", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.thasadith-bold {
  font-family: "Thasadith", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.thasadith-bold-italic {
  font-family: "Thasadith", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.bungee-hairline-regular {
  font-family: "Bungee Hairline", sans-serif;
  font-weight: 400;
  font-style: normal;
}


*, html, body {
  box-sizing:border-box;
}

html, body, .main-container {
height: 100vh;
padding: 0;
margin: 0;
font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.main-container {
  padding: 0;
  margin: 0;
}

h1 {
  font-family: "Thasadith", sans-serif;
  font-weight: 600;
  color:rgb(56, 56, 56);
}

h2 {
  font-family: "Thasadith", sans-serif;
  color: rgb(88,88,88);
  font-weight: 500;
}

h3 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
}

h4 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-weight: 400;
}

.blockquote {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 200;
  border-left: rgb(88, 88, 88) 4px solid;
  padding: 1em .75em 1em 1.5em;
  margin-left: 2.5em;
  margin-right: 2em;
  background-image: linear-gradient(to right,#B4C58D, white);
}

.loader {
  background-image: url(../public/matchacuponly.png);
  width: 100%;
  height: 100%;
}

a {
  color: rgb(88, 88, 88);
}

a:hover {
  color: #B4C58D;
  text-decoration: none;
}

ul {
  list-style:square;
}

.main-header {
  background-color: #ffffff;
}

#main-header-img {
  background-color: #f1f4ea;
  background-position:right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../public/matchacuponly.png');
  margin: 0 auto;
  border: rgb(88, 88, 88) solid;
  border-bottom: 0px;
  border-top: 0px;
  border-radius: 0;
  font-family: "Abril Fatface", serif;
}

.Site-header {
  background-color: rgb(180, 197, 141);
  font-size: calc(10px + 2vmin);
  font-family: "Abril Fatface", serif;
  color: rgb(88, 88, 88);
}

.Site-main-content-box {
  background-color: #f1f4ea;
  min-height: max-content;
  border: rgb(88, 88, 88) solid;
  border-top: 0px;
  border-bottom: 0px;
  padding: 0;
}


.home-jumbo {
  background-image: url("../public/personalphoto.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  max-height: 500px;
  margin: 0;
  margin-top: 1em;
  padding:0;
  opacity: .8;
}

.inner-alt-box {
  text-align: center;
  font-family: "Abril Fatface", serif;
  color: rgb(56, 56, 56);
  min-height: 100px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  margin: 0;
  padding: .25em 1em .25em 1em;
}

.inner-alt-box:hover {
  cursor: pointer;
  background-image: linear-gradient(#B4C58D, #d4debf, #ecf0e1);
  transition-duration: 500ms;
}

.Site-main-footer {
  background-color: #B4C58D;
  padding: 2em;
  border: solid rgb(88, 88, 88);
  margin-top: auto;
}

.footer-section-heading {
  font-family: "Thasadith", sans-serif;
  font-size: .9em;
  font-weight: 600;
  margin-left: 1em;
  padding-top: 2em;
}

.footer-section-col {

}

.footer-nav-link {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size: .75em;
}

.footer-list {
  list-style: none;
}

#footer-copyright {
  font-size: .6em;
  font-style: italic;
  text-align: center;
  padding-top: 1em;
}

.btn-close {
  height: 20px;
  color:rgb(88, 88, 88);
  border: none;
  background-color: transparent;
}

#postsFilterSortBox {
  display: none;
}

/* .navbar-collapse {
  background-color: white;;
} */

#menu-button-btn {
  color: rgb(88,88,88);
}

#main-nav {
  width: 100vw;
}

@media screen and (max-width: 768px) {
.navbar-collapse {
  background-color: #f1f4ea;
  height: 100vh;
}
.navbar {
  position: sticky;
  padding: 0;
  background-color: #f1f4ea;
}
.navbar-nav {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.navbar-toggler {
  background: #f1f4ea;
  margin: 0.5em 0.5em 0 0.5em;
}

.nav-link, .nav-item {
  padding: .5em;
  justify-items: center;
  align-items: space-between;
}


    };


@media screen and (min-width: 768px) {
  .navbar {
    background-color: #f1f4ea;
   
  }
  .navbar-expand-sm {
    background-color: #f1f4ea;
  }
}

.navbar {
  padding: 0;
  border-top: 3px rgb(88,88,88) solid;
    border-right: 3px rgb(88,88,88) solid; 
    border-left: 3px rgb(88,88,88) solid;
}

.accordionToggle:hover {
cursor: pointer;
background-image: radial-gradient(#B4C58D, white , white);
}

.bit-html-export {
  width: 100%;
  background: #fff;
  font-family: Arial;
  color: black;
  font-size: 12px;
}
.bit-table {
  width: 100%;
}
.bit-table td {
  padding: 7px 10px;
  vertical-align: middle;
}

.bit-table a {
  color: #00b4b3;
  text-decoration: none;
}

/* alternate row background color */
.bit-table tr:nth-of-type(2n) {
  background: #eee;
}

/* separate line between rows */
/* .bit-table tr td { border-bottom: 1px solid #ccc } */

.bit-table .bit-cta {
  text-align: center;
  width: 25%;
}